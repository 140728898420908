import React from 'react'
import { useWindowSize } from 'react-hooks-window-size'

import * as S from './styles'
import { breakpoints } from '../../../utils/styles/responsive'

export default function Home() {
  const size = useWindowSize()

  return (
    <S.Container>
      {size.width > breakpoints.medium && <S.ImageBackground src={require('./assets/background.png')} />}
      <S.ContentContainer>
        <S.LogoContainer>
          <S.LogoImage src={require('../../assets/logo.png')} />
          <S.LogoText href={'/'}>{'OneBeat'}</S.LogoText>
        </S.LogoContainer>
        <S.InfoContainer>
          <S.HeadlineContainer>
            <S.HeadlineText>{'Listen together'}</S.HeadlineText>
            <S.HeadlineText>{'wherever you are.'}</S.HeadlineText>
            <S.SubHeadlineText style={{ marginTop: 34 }}>
              {
                'OneBeat makes it easy to discover music and listen together with others like you. No algorithms and no bs. Just music and humans like you sharing the music they love.'
              }
            </S.SubHeadlineText>
            <a
              href="https://apps.apple.com/us/app/onebeat-listen-together/id1453853157"
              target="_blank"
              rel="noopener noreferrer"
            >
              <S.DownloadAppStore src={require('./assets/download-appstore.png')} />
            </a>
          </S.HeadlineContainer>
          <S.AppImage src={require('./assets/app.png')} />
          <S.Stats>
            <S.StatsHeader>{'11,132'}</S.StatsHeader>
            <S.StatsSubHeader>{'songs played'}</S.StatsSubHeader>
            <S.StatsHeader style={{ marginTop: 40 }}>{'6,435'}</S.StatsHeader>
            <S.StatsSubHeader>{'songs saved'}</S.StatsSubHeader>
            <S.StatsHeader style={{ marginTop: 40 }}>{'219'}</S.StatsHeader>
            <S.StatsSubHeader>{'sessions created'}</S.StatsSubHeader>
          </S.Stats>
        </S.InfoContainer>
        <S.Spacer></S.Spacer>
      </S.ContentContainer>
      <S.FooterContainer>
        <S.FooterText>{'© 2019 Double Symmetry UG'}</S.FooterText>
        <S.FooterDivider>{' | '}</S.FooterDivider>
        <S.FooterText href={'/privacy'}>{'Privacy'}</S.FooterText>
        <S.FooterDivider>{' | '}</S.FooterDivider>
        <S.FooterText href={'/terms'}>{'Terms'}</S.FooterText>
      </S.FooterContainer>
    </S.Container>
  )
}
