export default `
# TERMS OF SERVICE
Last Updated 15 September 2019

### 1. &nbsp; Agreement to Terms
1.1 &nbsp; These Terms of Service constitute a legally binding agreement made between you, whether personally or on behalf of an entity (**you**), and **Double Symmetry UG (haftungsbeschränkt)**, doing business as **Double Symmetry**, located at **Sprungschanzenweg 20, 14169 Berlin, Germany (we, us)**, concerning your access to and use of the **OneBeat (onebeat.io)** app as well as any related websites (the **App**).

The App provides services that **connect listeners to share & listen to music together (Services)**. You agree that by accessing the App and/or Services, you have read, understood, and agree to be bound by all of these Terms of Service.

**If you do not agree with all of these Terms of Service, then you are prohibited from using the App and Services and you must discontinue use immediately**. We recommend that you print a copy of these Terms of Service for future reference.

1.2 &nbsp; The supplemental policies set out in Section 1.7 below, as well as any supplemental terms and conditions or documents that may be posted on our website from time to time, are expressly incorporated by reference.

1.3 &nbsp; We may make changes to these Terms of Service at any time. The updated version of these Terms of Service will be indicated by an updated “Revised” date and the updated version will be effective as soon as it is accessible. You are responsible for reviewing these Terms of Service to stay informed of updates. Your continued use of the App represents that you have accepted such changes.

1.4 &nbsp; We may update or change the App from time to time to reflect changes to our products, our users' needs and/or our business priorities. 

1.5 &nbsp; Our App is directed to people residing in the EU. The information and content provided in the App is not intended for distribution to or use by any person or entity in any jurisdiction or country where such distribution or use would be contrary to law or regulation or which would subject us to any registration requirement within such jurisdiction or country. 

1.6 &nbsp; The App is intended for users who are at least 18 years old. If you are under the age of 18, you are not permitted to use the Services without parental permission. 

1.7 &nbsp; Additional policies which also apply to your use of the App include:

- Our Privacy Notice [https://onebeat.io/privacy](https://onebeat.io/privacy), which sets out the terms on which we process any personal data we collect from you, or that you provide to us. By using the App, you consent to such processing and you warrant that all data provided by you is accurate.

- 

### 2. &nbsp; Acceptable Use 

2.1 &nbsp; You may not access or use the App for any purpose other than that for which we make the app and our services available. The App may not be used in connection with any commercial endeavors except those that are specifically endorsed or approved by us. 

2.2 &nbsp; As a user of this App, you agree not to: 
 
- Systematically retrieve data or other content from the App or related websites to compile database or directory without written permission from us

- Engage in any automated use of the system, such as using scripts to send comments or messages, or using any data mining, robots, or similar data gathering and extraction tools 

- Use the App in a manner inconsistent with any applicable laws or regulations 

- Falsely imply a relationship with us or another company with whom you do not have a relationship  


### 3. &nbsp; Our content 

3.1 &nbsp; Unless otherwise indicated, the App and Services including source code, databases, functionality, software, website designs, audio, video, text, photographs, and graphics on the App and related websites (**Our Content**) are owned or licensed to us, and are protected by copyright and trademark laws.  

3.2 &nbsp; Except as expressly provided in these Terms of Service, no part of the App, Services or Our Content may be copied, reproduced, aggregated, republished, uploaded, posted, publicly displayed, encoded, translated, transmitted, distributed, sold, licensed, or otherwise exploited for any commercial purpose whatsoever, without our express prior written permission. 

3.3 &nbsp; You shall not (a) try to gain unauthorised access to the App, related websites or any networks, servers or computer systems connected to the App; and/or (b) make for any purpose including error correction, any modifications, adaptations, additions or enhancements to the App or Our Content. 

3.4 &nbsp; We shall (a) prepare the App and Our Content with reasonable skill and care; and (b) use industry standard filtering and well as virus detection software to try to block the queueing and distribution of content to the App that is inappropriate or contains malicious code. 

3.5 &nbsp; Although we make reasonable efforts to update the information on our App, we make no representations, warranties or guarantees, whether express or implied, that Our Content on the App is accurate, complete or up to date.  


### 4. &nbsp; Link to third party content  

4.1 &nbsp; The App may contain links to content, websites, applications operated by third parties. We do not have any influence or control over any such third party content, websites or applications or the third party operator. We are not responsible for and do not endorse any third party content, websites or applications or their availability.

4.2 &nbsp; We accept no responsibility for adverts contained within the App. If you agree to purchase goods and/or services from any third party who advertises in the App, you do so at your own risk. The advertiser, and not us, is responsible for such goods and/or services and if you have any questions or complaints in relation to them, you should contact the advertiser. 


### 5. &nbsp; App Management  

5.1 &nbsp; We reserve the right at our sole discretion, to (1) monitor the App for breaches of these Terms of Service; (2) take appropriate legal action against anyone in breach of applicable laws or these Terms of Service; (3) remove from the App or otherwise disable all files and content that are excessive in size or are in any way a burden to our systems; and (4) otherwise manage the App in a manner designed to protect our rights and property and to facilitate the proper functioning of the App and Services. 

5.2 &nbsp; We do not guarantee that the App will be secure or free from bugs. 

5.3 &nbsp; You are responsible for configuring your information technology, mobile devices, computer programs and platform to access the App and you should use your own virus protection software. 


### 6. &nbsp; Modifications to and availability of the App  

6.1 &nbsp; We reserve the right to change, modify, or remove the contents of the App at any time or for any reason at our sole discretion without notice. We also reserve the right to modify or discontinue all or part of the Services without notice at any time.

6.2 &nbsp; We cannot guarantee the App and Services will be available at all times. We may experience hardware, software, or other problems or need to perform maintenance related to the App, resulting in interruptions, delays, or errors. You agree that we have no liability whatsoever for any loss, damage, or inconvenience caused by your inability to access or use the App or Services during any downtime or discontinuance of the App or Services. We are not obliged to maintain and support the App or Services or to supply any corrections, updates, or releases. 

6.3 &nbsp; There may be information on the App that contains typographical errors, inaccuracies, or omissions that may relate to the Services, including descriptions, pricing, availability, and various other information. We reserve the right to correct any errors, inaccuracies, or omissions and to change or update the information at any time, without prior notice. 


### 7. &nbsp; Disclaimer/Limitation of Liability  

7.1 &nbsp; The App and Services are provided on an as-is and as-available basis. You agree that your use of the App and/or Services will be at your sole risk except as expressly set out in these Terms of Service. All warranties, terms, conditions and undertakings, express or implied (including by statute, custom or usage, a course of dealing, or common law) in connection with the App and Services and your use thereof including, without limitation, the implied warranties of satisfactory quality, fitness for a particular purpose and non-infringement are excluded to the fullest extent permitted by applicable law. 

We make no warranties or representations about the accuracy or completeness of the App’s content and are not liable for any (1) errors or omissions in content: (2) any unauthorized access to or use of our servers and/or any and all personal information and/or financial information stored on our server; (3) any interruption or cessation of transmission to or from the App or Services; and/or (4) any bugs, viruses, trojan horses, or the like which may be transmitted to or through the App by any third party. We will not be responsible for any delay or failure to comply with our obligations under these Terms of Service if such delay or failure is caused by an event beyond our reasonable control. 

7.2 &nbsp; Our responsibility for loss or damage suffered by you: 

Whether you are a consumer or a business user: 

- We do not exclude or limit in any way our liability to you where it would be unlawful to do so. This includes liability for death or personal injury caused by our negligence or the negligence of our employees, agents or subcontractors and for fraud or fraudulent misrepresentation. 

- If we fail to comply with these Terms of Service, we will be responsible for loss or damage you suffer that is a foreseeable result of our breach of these Terms of Service, but we would not be responsible for any loss or damage that were not foreseeable at the time you started using the App/Services. 

Notwithstanding anything to the contrary contained in the Disclaimer/Limitation of Liability section, our liability to you for any cause whatsoever and regardless of the form of the action, will at all times be limited to a total aggregate amount equal to the greater of (a) the sum of €5000 or (b) the amount paid, if any, by you to us for the Services/App during the six (6) month period prior to any cause of action arising.  

If you are a consumer user: 

- Please note that we only provide our App for domestic and private use. You agree not to use our App for any commercial or business purposes, and we have no liability to you for any loss of profit, loss of business, business interruption, or loss of business opportunity. 

- If defective digital content that we have supplied, damages a device or digital content belonging to you and this is caused by our failure to use reasonable care and skill, we will either repair the damage or pay you compensation.  

- You have legal rights in relation to goods that are faulty or not as described. Advice about your legal rights is available from your local Citizens' Advice Bureau or Trading Standards office. Nothing in these Terms of Service will affect these legal rights. 


### 8. &nbsp; Term and Termination  

8.1 &nbsp; These Terms of Service shall remain in full force and effect while you use the App or Services or are otherwise a user of the App, as applicable. You may terminate your use or participation at any time, for any reason, by following the instructions for terminating user accounts in your account settings, if available, or by contacting us at [support@doublesymmetry.com](mailto:support@doublesymmetry.com). 

8.2 &nbsp; Without limiting any other provision of these Terms of Service, we reserve the right to, in our sole discretion and without notice or liability, deny access to and use of the App and the Services (including blocking certain IP addresses), to any person for any reason including without limitation for breach of any representation, warranty or covenant contained in these Terms of Service or of any applicable law or regulation. 

If we determine, in our sole discretion, that your use of the App/Services is in breach of these Terms of Service or of any applicable law or regulation, we may terminate your use or participation in the App and the Services or delete any content or information that you posted at any time, without warning, in our sole discretion. 

8.3 &nbsp; If we terminate or suspend your account for any reason set out in this Section 8, you are prohibited from registering and creating a new account under your name, a fake or borrowed name, or the name of any third party, even if you may be acting on behalf of the third party. In addition to terminating or suspending your account, we reserve the right to take appropriate legal action, including without limitation pursuing civil, criminal, and injunctive redress. 


### 9. &nbsp; Mobile Application  

9.1 &nbsp; If you access the Services via a mobile application, then we grant you a revocable, non-exclusive, non-transferable, limited right to install and use the mobile application on wireless electronic devices owned or controlled by you, and to access and use the mobile application on such devices strictly in accordance with the terms of service of this license. 

9.2 &nbsp; **For consumers only** *- If you are a customer in the European Union, you have certain rights to decompile the Software if:*

- *this is necessary to obtain the information that you need to make the software interoperable with other software and*

- *we have not made that information available to you.*

Before reverse engineering or decompiling the software, you must first write to us and ask us to provide you with the interoperability information that you need. Please provide us with full details of your requirements so that we can assess what information you need. We may impose reasonable conditions on providing you with interoperability information. You must use that information only for the purpose of making the software interoperable with other software. You must not use that information for any other purpose.
  
9.3 The following terms apply when you use a mobile application obtained from either the Apple Store or Google Play (each an App Distributor) to access the Services: 
 
(a) The licence granted to you for our mobile application is limited to a non-transferable licence to use the application on a device that utilizes the Apple iOS or Android operating system, as applicable, and in accordance with the usage rules set forth in the applicable App Distributor terms of service; 
 

(b) We are responsible for providing any maintenance and support services with respect to the mobile application as specified in these Terms of Service or as otherwise required under applicable law. You acknowledge that each App Distributor has no obligation whatsoever to furnish any maintenance and support services with respect to the mobile application; 
 

(c) In the event of any failure of the mobile application to conform to any applicable warranty, you may notify an App Distributor, and the App Distributor, in accordance with its terms and policies, may refund the purchase price, if any, paid for the mobile application, and to the maximum extent permitted by applicable law, an App Distributor will have no other warranty obligation whatsoever with respect to the mobile application; 
 

(d) You represent and warrant that (i) you are not located in a country that is subject to a U.S. government embargo, or that has been designated by the U.S. government as a “terrorist supporting” country; and (ii) you are not listed on any U.S. government list of prohibited or restricted parties; 
 

(e) You must comply with applicable third party terms of agreement when using the mobile application, e.g., if you have a VoIP application, then you must not be in breach of their wireless data service agreement when using the mobile application; and 
 

(f) You acknowledge and agree that the App Distributors are third party beneficiaries of these Terms of Service, and that each App Distributor will have the right (and will be deemed to have accepted the right) to enforce these Terms of Service against you as a third party beneficiary thereof.  

 
### 10. &nbsp; General  

10.1 &nbsp; Using the App, visiting related websites, sending us emails, and completing online forms constitute electronic communications. You consent to receive electronic communications and you agree that all agreements, notices, disclosures, and other communications we provide to you electronically, via email and on the App, satisfy any legal requirement that such communication be in writing. 

**You hereby agree to the use of electronic signatures, contracts, orders and other records and to electronic delivery of notices, policies and records of transactions initiated or completed by us or via the App**. You hereby waive any rights or requirements under any statutes, regulations, rules, ordinances or other laws in any jurisdiction which require an original signature or delivery or retention of non-electronic records, or to payments or the granting of credits by other than electronic means. 

10.2 &nbsp; These Terms of Service and any policies or operating rules posted by us on our website or in respect to the Services constitute the entire agreement and understanding between you and us.  

10.3 &nbsp; Our failure to exercise or enforce any right or provision of these Terms of Service shall not operate as a waiver of such right or provision.  

10.4 &nbsp; We may assign any or all of our rights and obligations to others at any time.  

10.5 &nbsp; We shall not be responsible or liable for any loss, damage, delay or failure to act caused by any cause beyond our reasonable control.  

10.6 &nbsp; If any provision or part of a provision of these Terms of Service is unlawful, void or unenforceable, that provision or part of the provision is deemed severable from these Terms of Service and does not affect the validity and enforceability of any remaining provisions. 

10.7 &nbsp; There is no joint venture, partnership, employment or agency relationship created between you and us as a result of these Terms of Service or use of the App or Services. 

10.8 &nbsp; **For consumers only** *- Please note that these Terms of Service, their subject matter and their formation, are governed by German law. If you have any complaint or wish to raise a dispute under these Terms of Service or otherwise in relation to the App please follow this link [http://ec.europa.eu/odr](http://ec.europa.eu/odr).*

10.9 &nbsp; Except as stated under the Mobile Application section, a person who is not a party to these Terms of Service shall have no right under the Contracts (Rights of Third Parties) Act 1999 to enforce any term of these Terms of Service. 

10.10 &nbsp; In order to resolve a complaint regarding the Services or to receive further information regarding the use of the Services, please contact us by email at [support@doublesymmetry.com](mailto:support@doublesymmetry.com) or by post to: 

&nbsp;
&nbsp;

Double Symmetry UG (haftungsbeschränkt)  
Sprungschanzenweg 20  
Berlin, Berlin 14169  
Germany  
`
