import styled from 'styled-components'
import { smaller, breakpoints, widerThan } from '../../../utils/styles/responsive'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  ${{ [smaller(breakpoints.medium)]: { textAlign: 'center' } }}
`

export const ImageBackground = styled.img`
  display: flex;
  position: absolute;
  height: 71%;
  width: 100%;
  z-index: -1;
`

export const ContentContainer = styled.div`
  display: flex;
  padding-left: 7.5%;
  ${{ [widerThan(breakpoints.medium)]: { paddingLeft: '7.5%' } }}
  ${{ [smaller(breakpoints.medium)]: { paddingLeft: 20, paddingRight: 20 } }}
  flex-direction: column;
`

export const LogoContainer = styled.div`
  display: flex;
  margin-top: 2.3%;
  flex-direction: row;
  align-items: center;
  ${{ [smaller(breakpoints.medium)]: { marginTop: '8%' } }}
`

export const LogoImage = styled.img`
  width: 40px;
  height: 40px;
`

export const LogoText = styled.a`
  color: #918eff;
  font-size: 24px;
  margin-left: 6px;
  font-weight: 500;
  text-decoration: none;
`

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  ${{ [smaller(breakpoints.medium)]: { flexDirection: 'column' } }}
`

export const HeadlineContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  ${{ [smaller(breakpoints.medium)]: { marginTop: 80 } }}
`

export const HeadlineText = styled.div`
  color: #3e3e42;
  font-size: 42px;
  font-weight: bold;
  ${{ [smaller(breakpoints.medium)]: { fontSize: 36 } }}
`

export const SubHeadlineText = styled.div`
  color: #3e3e42;
  font-size: 22px;
  font-weight: 300;
  line-height: 32px;
  max-width: 560px;
  ${{ [smaller(breakpoints.medium)]: { fontSize: 18 } }}
`

export const AppImage = styled.img`
  width: 400px;
  height: 742px;
  ${{ [smaller(breakpoints.medium)]: { width: 330, height: 662 } }}
`

export const Stats = styled.div`
  display: flex;
  margin-top: 90px;
  margin-left: 20px;
  margin-right: 14%;
  align-self: flex-start;
  flex-direction: column;
  ${{
    [smaller(breakpoints.medium)]: {
      width: '100%',
      marginTop: 20,
      marginLeft: 0,
      marginRight: 0,
      paddingBottom: 40,
    },
  }}
`

export const StatsHeader = styled.div`
  color: white;
  font-size: 40px;
  font-weight: bold;
  ${{ [smaller(breakpoints.medium)]: { color: '#3e3e42' } }}
`
export const StatsSubHeader = styled.div`
  color: white;
  font-size: 30px;
  font-weight: 400;
  ${{ [smaller(breakpoints.medium)]: { color: '#3e3e42' } }}
`

export const DownloadAppStore = styled.img`
  width: 192px;
  height: 64px;
  margin-top: 46px;
  ${{ [smaller(breakpoints.medium)]: { alignSelf: 'center' } }}
`

export const Spacer = styled.div`
  width: 20px;
  height: 14vh;
`

export const FooterContainer = styled.div`
  width: 100%;
  display: flex;
  text-align: center;
  align-self: center;
  flex-direction: row;
  padding-bottom: 20px;
  justify-content: center;
  ${{ [smaller(breakpoints.phone)]: { flexDirection: 'column' } }}
`

export const FooterText = styled.a`
  color: #3e3e42;
  font-size: 18px;
  font-weight: 300;
  max-width: 560px;
`

export const FooterDivider = styled.a`
  color: #3e3e42;
  font-size: 18px;
  font-weight: 300;
  max-width: 560px;
  margin-left: 20px;
  margin-right: 20px;
  ${{ [smaller(breakpoints.phone)]: { opacity: 0 } }}
`
