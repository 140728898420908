import styled from 'styled-components'
import { smaller, breakpoints, widerThan } from '../../../utils/styles/responsive'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`

export const ImageBackground = styled.img`
  display: flex;
  position: absolute;
  height: 71%;
  width: 100%;
  z-index: -1;
`

export const ContentContainer = styled.div`
  display: flex;
  padding-left: 7.5%;
  ${{ [widerThan(breakpoints.medium)]: { paddingLeft: '7.5%' } }}
  ${{ [smaller(breakpoints.medium)]: { paddingLeft: 20, paddingRight: 20 } }}
  flex-direction: column;
`

export const LogoContainer = styled.div`
  display: flex;
  margin-top: 2.3%;
  flex-direction: row;
  align-items: center;
  ${{ [smaller(breakpoints.medium)]: { marginTop: '8%' } }}
`

export const LogoImage = styled.img`
  width: 40px;
  height: 40px;
`

export const LogoText = styled.a`
  color: #918eff;
  font-size: 24px;
  margin-left: 6px;
  font-weight: 500;
  text-decoration: none;
`

export const PolicyContainer = styled.div`
  display: flex;
  max-width: 50%;
  flex-direction: column;
  ${{ [smaller(breakpoints.medium)]: { maxWidth: '100%' } }}
`

export const Spacer = styled.div`
  width: 20px;
  height: 14vh;
`

export const FooterContainer = styled.div`
  width: 100%;
  display: flex;
  text-align: center;
  align-self: center;
  flex-direction: row;
  padding-bottom: 20px;
  justify-content: center;
`

export const FooterText = styled.a`
  color: #3e3e42;
  font-size: 18px;
  font-weight: 300;
  max-width: 560px;
  ${{ [smaller(breakpoints.medium)]: { fontSize: 18 } }}
`

export const Heading1 = styled.div`
  color: #3e3e42;
  margin-top: 5%;
  font-size: 32px;
  font-weight: bold;
  ${{ [smaller(breakpoints.medium)]: { fontSize: 24, marginTop: '10%' } }}
`

export const Heading3 = styled.div`
  color: #3e3e42;
  margin-top: 5%;
  font-size: 24px;
  font-weight: bold;
  ${{ [smaller(breakpoints.medium)]: { fontSize: 18, marginTop: '10%' } }}
`

export const Paragraph = styled.p`
  color: #3e3e42;
  font-size: 18px;
  font-weight: 300;
  ${{ [smaller(breakpoints.medium)]: { fontSize: 14 } }}
`

export const Strong = styled.strong`
  color: #3e3e42;
  font-size: 18px;
  font-weight: 600;
  ${{ [smaller(breakpoints.medium)]: { fontSize: 14 } }}
`
