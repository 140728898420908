import React from 'react'
import { Markdown } from 'react-markdown-tree'
import { MarkdownProvider } from 'react-markdown-tree'

import * as S from '../Privacy/styles'
import markdown from './markdown'

export default function Terms() {
  return (
    <MarkdownProvider
      config={{
        renderers: {
          Heading: (props: { level: number; children: any }) => {
            switch (props.level) {
              case 1:
                return <S.Heading1>{props.children}</S.Heading1>
              case 3:
                return <S.Heading3>{props.children}</S.Heading3>
            }
          },
          Paragraph: (props: any) => <S.Paragraph>{props.children}</S.Paragraph>,
          Strong: (props: any) => <S.Strong>{props.children}</S.Strong>,
        },
      }}
    >
      <S.Container>
        <S.ContentContainer>
          <S.LogoContainer>
            <S.LogoImage src={require('../../assets/logo.png')} />
            <S.LogoText href={'/'}>{'OneBeat'}</S.LogoText>
          </S.LogoContainer>
          <S.PolicyContainer>
            <Markdown>{markdown}</Markdown>
          </S.PolicyContainer>
          <S.Spacer></S.Spacer>
        </S.ContentContainer>
        <S.FooterContainer>
          <S.FooterText>{'© 2019 Double Symmetry UG'}</S.FooterText>
        </S.FooterContainer>
      </S.Container>
    </MarkdownProvider>
  )
}
