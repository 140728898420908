export default `
# Privacy Policy
We take your right to privacy very seriously. All data collected, processed and stored in conjunction with your usage of the OneBeat app and related services are laid out in this Privacy Policy.

### Information collected and processed

During your usage of the OneBeat app anonymous usage data is transmitted from your device and processed and stored with Amplitude Analytics Inc., 501 2nd Street Suite 100, San Francisco, California 94107, USA. This data may include technical information that can be considered personal information under specific circumstances, such as your IP address, what country you reside in and the make and model of the mobile device you’re using. We will never attempt to use this data to identify you, and we will never pass on any data to any third parties not laid out in this Privacy Policy unless we are required to do so by law. The data collected in this way is used exclusively to operate and improve the app and any surrounding services we provide.

Usage of the OneBeat app does not require an account. No email or passwords can be provided or stored.

As part of the service offered by the OneBeat app, you can connect to your existing Spotify account to allow streaming music from the spotify music streaming service. All login infrastructure is provided by Spotify and all information relating to your Spotify account and usage of the service are handled and stored exclusively by Spotify AB, Regeringsgatan 19, 111 53 Stockholm, Sweden.

We do not store any personal information relating to the use of the Spotify service. In order to allow usage of the Spotify music streaming service we store an anonymized Token provided by Spotify. This token is stored locally in your device and allows Spotify to verify your account with them. It can not be used by us to personally identify you.

In specific circumstances, such as when the app crashes unexpectedly, we collect and store technical information about the crash through Sentry, 132 Hawthorne St., San Francisco, California 94107, USA. Information collected will include device make and model, operating system and the version of the OneBeat app installed on the device, among other technical details. We will use this information to improve the stability of the OneBeat app and it’s related services.

### What information is accessible in-app to others

The OneBeat app allows listening to music together with others around the world. At no point does OneBeat display any personal information about listeners to other listeners. When you join a session that other listeners are currently part of, they may be notified that a listener has joined. At any given moment the App will display how many listeners are currently listening to music in any of the sessions. There is no option in the app to distinguish or identify listeners.

### Right to deletion and correction

We collect anonymous statistical data of how the OneBeat app is used. This does not include any personal information about you. We do not support the creation of personal accounts, and as such we can not and will not attempt to connect usage data with specific individuals. Any data you enter into the app voluntarily is stored locally on your device, and you may change and delete any such data at your own discretion. When you delete the app from your device that data is also deleted.

### Security

We use industry standard security measures to protect the communication that happens between your device and our servers and any data that is stored with us. All communication with our servers happens over encrypted connections. We do not store or submit any email information or passwords.

### Contact

If you have any questions or suggestions concerning privacy, get in touch with us at [support@doublesymmetry.com](mailto:support@doublesymmetry.com)

Double Symmetry UG (haftungsbeschränkt) is a company duly incorporated in Germany at Sprungschanzenweg 20 14019, Berlin.
`
